import { get } from 'lodash';

// TODO: move this into some shared repo, but for now, keep this in sync with
// https://github.com/underdog-tech/dev-dashboard-api/blob/main/src/common/errors/DevDashboardApiError.ts
export enum ErrorCode {
  InvalidCredentials = "INVALID_CREDENTIALS",
  InvalidToken = "INVALID_TOKEN",
  InvalidInvitationKey = "INVALID_INVITATION_KEY",
  MaxRefreshTokenTries = "MAX_REFRESH_TOKEN_TRIES",
  MismatchedPasswordAndConfirmation = "MISMATCHED_PASSWORD_AND_CONFIRMATION",
  NewPasswordRequired = "NEW_PASSWORD_REQUIRED",
  PasswordTooLong = "PASSWORD_TOO_LONG",
  WorkspaceNotFound = "WORKSPACE_NOT_FOUND",
  APIKeyNotFound = "API_KEY_NOT_FOUND",
  UserNotFound = "USER_NOT_FOUND",
  UserNotWorkspaceMember = "USER_NOT_WORKSPACE_MEMBER",
  UserAlreadyWorkspaceMember = "USER_ALREADY_WORKSPACE_MEMBER",
  EmployerMatchingJobExists = "EMPLOYER_MATCHING_JOB_EXISTS",
  APIKeyLimitReached = "API_KEY_LIMIT_REACHED",
  InternalServerError = "INTERNAL_SERVER_ERROR",
}

const HumanErrorMessages = {}
HumanErrorMessages[ErrorCode.APIKeyLimitReached] = 
    "You've reached your limit of active keys. To create a new key, you'll have to revoke an active one."
HumanErrorMessages[ErrorCode.InternalServerError] = 
    "We ran into a problem completing this request. Try again, but if this persists, please contact support."

export function errorMessageForCode(errorCode: ErrorCode): string | undefined {
    return HumanErrorMessages[errorCode]
}

export class DevDashboardError extends Error {
  code: ErrorCode;
  operation: string | null;
  constructor(message: string, code?: ErrorCode, operation?: string) {
    super(message);
    this.code = code || ErrorCode.InternalServerError
    this.operation = operation || null
    Object.defineProperty(this, 'name', { value: 'DevDashboardApiError' });
  }

  static fromException(error: Error, operation?: string): DevDashboardError {
    // try to get the code from an ApolloError or ErrorResponse
    const code = get(
        error, 
        'extensions.code', 
        get(error, 'graphQLErrors[0].extensions.code', ErrorCode.InternalServerError)) as ErrorCode
    return new DevDashboardError(error.message, code, operation)
  }
}
